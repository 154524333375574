"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "lucide-react";
import Image from "next/image";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { getRandomOrcishQuote } from "@/components/shared/data";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { subscribe } from "@/server/newsletter";

const formSchema = z.object({
  email: z.string().email(),
  username: z.string().trim().min(3).max(20),
});

export default function Newsletter() {
  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      username: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setIsLoading(true);
      const subscriptionResult = await subscribe(values.email, values.username);
      toast({
        title: subscriptionResult,
        description: getRandomOrcishQuote(),
      });
    } catch (error) {
      toast({
        title: "Could not subscribe",
        description: "Please try again later",
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section
      id="newsletter"
      className="grid items-center justify-center px-5 text-center md:text-left lg:px-24 xl:grid-cols-2"
    >
      <div className="flex flex-col gap-5 p-10">
        <h2 className="text-3xl">
          Subscribe to the Warcry of the
          <br />
          <span className="text-nowrap text-green-700">
            Web Dev Warrior!
          </span>{" "}
          ⚔️
        </h2>
        <p>
          If you are interested in quick web development coding tips and tricks,
          be sure to subscribe to the newsletter! You are also going to get your
          Web Dev Warrior banner to proudly display wherever you want!
        </p>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-5 md:flex-row"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage className="dark:text-red-400 md:absolute" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input placeholder="Username" {...field} />
                  </FormControl>
                  <FormMessage className="dark:text-red-400 md:absolute" />
                </FormItem>
              )}
            />
            <Button
              disabled={
                form.getFieldState("email").invalid ||
                form.getFieldState("username").invalid ||
                isLoading
              }
              className="min-w-20"
              type="submit"
            >
              {isLoading ? (
                <Loader className="size-4 animate-spin" />
              ) : (
                "Subscribe"
              )}
            </Button>
          </form>
        </Form>
      </div>
      <div className="grid items-center gap-5 p-5 px-10 text-center md:grid-cols-2 md:p-10">
        <div>
          <h2 className="text-3xl">Warrior Banner</h2>
        </div>
        <div className="hidden md:block">
          <h2 className="text-3xl">Coding advice</h2>
        </div>
        <div className="flex items-center justify-center">
          <a href="https://orcdev.com/banner?warrior=869a6c8386505f42723beeb5fdf17fdaa51030b00d86a3e3c9c5a9550cb946a7">
            <Image
              src="https://orcdev.com/banners/869a6c8386505f42723beeb5fdf17fdaa51030b00d86a3e3c9c5a9550cb946a7.png"
              width="180"
              height="180"
              alt="OrcDev's Dev Card"
            />
          </a>
        </div>
        <div className="block md:hidden">
          <h2 className="text-3xl">Coding advice</h2>
        </div>
        <div className="flex items-center justify-center">
          <Image
            src={"/images/newsletter/mail-orc.png"}
            width={240}
            height={240}
            alt="OrcDev Newsletter"
          />
        </div>
      </div>
    </section>
  );
}
